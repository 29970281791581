import { useSeoStore } from '~/store/seo';
import { usePageStore } from '~/store/pagesData';
import type { MetaObject } from 'nuxt/schema';
import { getISODateString } from '@devhacker/shared/utils/date';
import { trailingSlash } from '~/utils';
import makePreloadLinksForArticleCardCover from '~/libs/makePreloadLinksForArticleCardCover';
import { isArchiveByDatePage } from '~/utils/router';

export const useHeadWithSeo = () => {
  const routeName = useRoute().name;
  const seoStore = useSeoStore();

  const article = usePageStore().currentArticle;
  const isSingleArticle: boolean = routeName === 'single' || routeName === 'preview';

  const {
    title,
    description,
    robots,
    canonical,
    schema_graph = '',
    og: { image } = {},
    schema_recipe,
    schema_howto,
  } = seoStore.currentSeo;

  const ldJsonContent: string = schema_graph.replace(/<script.*?>(.*)<\/script>/, '$1');

  const singleArticleMeta: MetaObject['meta'] = [];

  if (isSingleArticle && article) {
    const {
      categories,
      author: { name },
      date,
      isAdvertising,
      isPromo,
      tags,
    } = article;

    const addToMeta = (key: string, value: string) => {
      singleArticleMeta.push({ hid: key, name: key, content: value });
    };

    addToMeta('lh_author', name);

    const firstTopic = categories.find((item): boolean => item.url.includes('topics/'));
    const firstTopicTitle = firstTopic?.title ?? 'null';
    addToMeta('lh_category', firstTopicTitle);

    const firstStream = categories.find((item): boolean => item.url.includes('stream/'));
    const firstStreamTitle = firstStream?.title ?? 'null';
    addToMeta('lh_stream', firstStreamTitle);

    const technicalTags: string[] = ['noad', 'nozen', 'hide', 'noturbo', 'noadsense'];
    const notTechnicalTags = tags.filter((item): boolean => !technicalTags.includes(item.title));
    const firstTagTitle = notTechnicalTags[0]?.title ?? 'null';
    addToMeta('lh_tag', firstTagTitle);

    const partnerValueToSet = isAdvertising || isPromo ? 'Партнёрский материал' : 'null';
    addToMeta('lh_partner', partnerValueToSet);

    const ISODate = getISODateString(new Date(1000 * date));
    addToMeta('lh_published_time', ISODate);

    const firstPublishedDate = article?.meta?.lh_first_published_time || null;
    const ISOFirstPublishedDate =
      firstPublishedDate !== null ? getISODateString(new Date(firstPublishedDate * 1000)) : 'null';
    addToMeta('lh_first_published_time', ISOFirstPublishedDate);
  }

  const link: MetaObject['link'] = [
    { hid: 'canonical', rel: 'canonical', href: canonical },
    { hid: 'image_src', rel: 'image_src', href: image },
  ];
  if (article?.ampEnabled) {
    link.push({
      hid: 'amphtml',
      rel: 'amphtml',
      href: `${trailingSlash(canonical)}amp/`,
    });
  }

  if (import.meta.server) {
    const preloadCoversLinks = makePreloadLinksForArticleCardCover(
      routeName,
      usePageStore().currentArticles,
      article,
    );
    link.push(...preloadCoversLinks);
  }

  const getTitle = (): string => {
    if (useError().value) {
      return 'Страница не найдена - Лайфхакер';
    }
    return title;
  };

  useHead({
    link,
    title: getTitle(),
    meta: [
      { hid: 'og:title', property: 'og:title', content: title },
      { hid: 'og:image', property: 'og:image', content: image ?? '' },
      { hid: 'og:description', property: 'og:description', content: description },
      { hid: 'og:url', property: 'og:url', content: canonical },
      { hid: 'description', name: 'description', content: description },

      // twitter
      { hid: 'twitter:title', property: 'twitter:title', content: title },
      { hid: 'twitter:description', property: 'twitter:description', content: description },
      { hid: 'twitter:card', property: 'twitter:card', content: 'summary_large_image' },
      { hid: 'twitter:image', property: 'twitter:image', content: image ?? '' },

      {
        hid: 'robots',
        name: 'robots',
        // В архивах по датам нужно отключить индексацию https://youtrack.lifehacker.ru/issue/LH-1042
        content: isArchiveByDatePage(routeName) ? 'noindex, nofollow' : robots,
      },

      // LH-1552
      {
        hid: 'lh_page_title',
        name: 'lh_page_title',
        content: title,
      },

      ...singleArticleMeta,
    ],
    script: [
      ...(ldJsonContent
        ? [
            {
              type: 'application/ld+json',
              // class: 'yoast-schema-graph yoast-schema-graph--main',
              innerHTML: ldJsonContent,
            },
          ]
        : []),
      ...(article?.jsonLD
        ? [
            {
              type: 'application/ld+json',
              innerHTML: article?.jsonLD,
            },
          ]
        : []),
      ...(schema_recipe
        ? [
            {
              type: 'application/ld+json',
              // class: 'yoast-schema-graph yoast-schema-graph--main',
              innerHTML: schema_recipe,
            },
          ]
        : []),
      ...(schema_howto
        ? [
            {
              type: 'application/ld+json',
              innerHTML: schema_howto,
            },
          ]
        : []),
    ],
  });
};
